<template>
  <div>
    <v-row dense>
      <v-col cols="12" v-for="(campagne, index) in listOfCampagnes" :key="index">
        <campagneCard
          :amountOfUsers="campagne.amountOfUsers"
          :title="campagne.title"
          :urlItem="campagne.url"
          @setSnackbar="setSnackbar($event)"
        />
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :color="color" app>
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
    
    <script>
import request from "../../request";
export default {
  data() {
    return {
      snackbar: false,
      text: "",
      color: "",
      listOfCampagnes: [
        {
          title: "Nieuwe collectie brillen",
          url: "/api/optician/send-new-glasses",
          amountOfUsers: "",
        },
        {
          title: "Nieuwe collectie zonnebrillen",
          url: "/api/optician/send-new-collection-sun-glasses",
          amountOfUsers: "",
        },
        {
          title: "Reservebril - brillendragers",
          url: "/api/optician/send-spare-glasses-glasses",
          amountOfUsers: "",
        },
        {
          title: "Reservebril - lenzendragers",
          url: "/api/optician/send-spare-glasses-lenses",
          amountOfUsers: "",
        },
        {
          title: "Reservebril - ouders",
          url: "/api/optician/send-spare-glasses-parents",
          amountOfUsers: "",
        }
      ],
    };
  },
  created() {
    const urls = [
      "/api/optician/new-glasses-count",
      "/api/optician/new-collection-sun-glasses-count",
      "/api/optician/spare-glasses-glasses-count",
      "/api/optician/spare-glasses-lenses-count",
      "/api/optician/spare-glasses-parents-count",
    ];
    request.getAll(urls, null, (res) => {
      if (res[0]) {
        this.listOfCampagnes[0].amountOfUsers = res[0].item;
      }
      if (res[1]) {
        this.listOfCampagnes[1].amountOfUsers = res[1].item;
      }
      if (res[2]) {
        this.listOfCampagnes[2].amountOfUsers = res[2].item;
      }
      if (res[3]) {
        this.listOfCampagnes[3].amountOfUsers = res[3].item;
      }
      if (res[4]) {
        this.listOfCampagnes[4].amountOfUsers = res[4].item;
      }
    });
  },
  methods: {
    setSnackbar(event) {
      this.snackbar = true;
      this.text = event.text;
      this.color = event.color;
    },
  },
};
</script>
    
    <style>
</style>